<template>
	<b-container fluid>
		<b-row>
			<b-col>
				<b-alert variant="danger" v-if="error" show dismissible>
					ERROR: {{error}}
				</b-alert>
			</b-col>
		</b-row>
		<b-row>
			<b-col>
				<b-card no-body style="height: 80vh; overflow-y: auto">
					<b-tabs card id="diagnosticstab">
						<template v-slot:tabs-end>
						</template>
						<b-tab lazy title="Build Info" active>
							<b-card-text>
								<br />
								<div class="h4 pt-4">Web</div>
								<div class="d-flex flex-column mt-2">
									<div>
										<label>Build:</label> {{BUILD_TIMESTAMP}}
									</div>
									<div>
										<label>Build Hash:</label> {{BUILD_HASH}}
									</div>
								</div>
								<div class="h4 pt-4">
									Application Server
									<span>
										<b-btn size="sm" variant="success" @click="getBuildInfo">Get</b-btn>
									</span>
								</div>
								<div class="d-flex flex-column mt-2" v-if="getServerEnvironmentInfoResult">
									<div>
										<label>Build:</label> {{getServerEnvironmentInfoResult.buildTimeStamp}}
									</div>
									<div>
										<label>Runtime:</label> {{getServerEnvironmentInfoResult.runtimeIdentifier}}
									</div>
									<div>
										<label>Framework:</label> {{getServerEnvironmentInfoResult.frameworkDescription}}
									</div>
									<div>
										<label>OS:</label> {{getServerEnvironmentInfoResult.osDescription}}
									</div>
									<div>
										<label>OS-Arch:</label> {{getServerEnvironmentInfoResult.osArchitecture}}
									</div>
									<div>
										<label>Processor-Arch:</label> {{getServerEnvironmentInfoResult.processArchitecture}}
									</div>
								</div>
							</b-card-text>
						</b-tab>

						<b-tab lazy title="Server Info">
							<b-card-text>
								<b-btn variant="success" @click="getServerEnvironmentInfo">Get Server Environment Info</b-btn>
								<br />
								<br />
								<b-alert show variant="secondary">{{getServerEnvironmentInfoResult}}</b-alert>
							</b-card-text>

							<b-card-text>
								<b-btn variant="success" @click="getServerEnvironmentInfo2">Get Server Environment Info 2</b-btn>
								<br />
								<br />
								<b-alert show variant="secondary">{{getServerEnvironmentInfo2Result}}</b-alert>
							</b-card-text>
						</b-tab>

						<b-tab lazy title="Send Mail">
							<b-card-text>
								<div class="float-right">
									<b-btn variant="success" @click="sendMail">Send Mail</b-btn>
								</div>
								<br />
								<b-form class="pt-4">
									<b-form-row>
										<b-col cols="4">
											<b-form-input id="to"
														  v-model.trim="to"
														  type="text"
														  placeholder="TO" />
										</b-col>
									</b-form-row>
									<b-form-row class="pt-2">
										<b-col>
											<b-form-input id="subject"
														  v-model.trim="subject"
														  type="text"
														  placeholder="subject"
														  required />
										</b-col>
									</b-form-row>
									<b-form-row class="pt-2">
										<b-col>
											<b-form-textarea id="body"
															 v-model.trim="body"
															 type="textarea"
															 placeholder="body"
															 rows="4"
															 required />
										</b-col>
									</b-form-row>
								</b-form>
								<b-alert show variant="secondary">{{sendMailResult}}</b-alert>
							</b-card-text>
						</b-tab>

						<b-tab lazy title="SignalR">
							<b-card-text>
								<br />
								<br />
								<b-row>
									<b-col cols="6">
										<b-btn variant="success" @click="sendSignalRMessage">Send SignalR Test Message</b-btn>
										<b-alert v-show="sendSignalRMessageResult" show variant="secondary">{{sendSignalRMessageResult}}</b-alert>
										<br />
										<br />
										<br />
										<b-btn variant="success" @click="sendSignalRMessage2">Send SignalR Test Message 2</b-btn>
										<b-alert v-show="sendSignalRMessage2Result" show variant="secondary">{{sendSignalRMessage2Result}}</b-alert>
									</b-col>
									<b-col cols="6" style="max-height: 300px; overflow-y: auto">
										<b-alert v-for="(response, responseindex) in sendSignalRMessageResponses" :key="'signalrmessage'+responseindex" show variant="secondary">
											<div>
												{{response.message}}<br />
												<span class="small">{{response.receivedat}}</span>
											</div>
										</b-alert>
									</b-col>
								</b-row>
							</b-card-text>
						</b-tab>

						<b-tab lazy title="Cache">
							<b-card-text>
								<br />
								<br />
								<b-row>
									<b-col cols="6">
										<b-row>
											<b-col cols="12">
												<b-form inline>
													<label class="sr-only" for="key">Key</label>
													<b-form-input id="key" class="col-3" placeholder="key" v-model.trim="cacheKey" />

													<label class="sr-only" for="value">Value</label>
													<b-form-input id="value" class="col-3" placeholder="value" v-model.trim="cacheValue" />

													<label class="sr-only" for="expiresinseconds">Expires(Seconds)</label>
													<b-form-input id="expiresinseconds" class="col-2" placeholder="expires (seconds)" v-model.number="expiresInSeconds" />

													<b-button variant="primary" @click="setCacheValue">Set Cache Value</b-button>
												</b-form>
											</b-col>
										</b-row>
										<br />
										<b-btn variant="success" @click="getCacheValue">Get Cache Value</b-btn>
										<b-btn variant="success" @click="getCacheValue2">Get Cache Value 2</b-btn>
										<b-alert v-show="cacheValueResult" show variant="secondary">{{cacheValueResult}}</b-alert>
									</b-col>
								</b-row>
							</b-card-text>
						</b-tab>

						<b-tab lazy title="Known Urls">
							<b-card-text>
								<b-link href="/manage/users" target="_blank">Users</b-link><br />
								<b-link href="/manage/gamedefinitions/my" target="_blank">Game Definitions - My</b-link><br />
								<b-link href="/manage/gamedefinitions/published" target="_blank">Game Definitions - Published</b-link><br />
								<br />

								<b-link href="/manage/games/all" target="_blank">Games</b-link><br />
								<b-link href="/manage/games/myowned" target="_blank">My Owned</b-link><br />
								<b-link href="/manage/games/invited" target="_blank">Invited</b-link><br />
								<b-link href="/manage/games/joined" target="_blank">Joined</b-link><br />
								<br />

								<b-link :href="this.baseurl + ':27018/'" target="_blank">Db - Mongo</b-link><br />
								<b-link :href="this.baseurl + ':6380/'" target="_blank">Cache - Redis</b-link><br /><br />
								<b-link :href="this.baseurl + '/logout'">Logout</b-link><br />
							</b-card-text>
						</b-tab>
					</b-tabs>
				</b-card>
			</b-col>
		</b-row>
	</b-container>
</template>
<script lang="js">
	import { mapState, mapActions } from 'vuex';
	import DEBUG from '@/common/DEBUG';

	import VueJsonPretty from 'vue-json-pretty';
	import 'vue-json-pretty/lib/styles.css';

	import playingcard from '@/components/game/playingcard';
	import pass from '@/components/pass';

	export default {
		name: 'diagnostics',

		components: {
			pass,
			playingcard,
			VueJsonPretty,
		},

		data() {
			return {
				baseurl: '//' + window.location.hostname,

				error: null,
				isprocessing0: false,
				isprocessing1: false,
				getServerEnvironmentInfoResult: null,

				isprocessing2: false,
				getServerEnvironmentInfo2Result: null,

				isprocessing3: false,
				from: 'no-reply@zoomgamez.com',
				to: null,
				subject: 'This is a test mail - ' + new Date(),
				body: `<strong>What is Lorem Ipsum?</strong>
Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.

<strong>Why do we use it?</strong>
It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).
`,
				sendMailResult: null,

				isprocessing4: false,
				sendSignalRMessageResult: null,
				sendSignalRMessage2Result: null,
				sendSignalRMessageResponses: [],

				cacheKey: "key1",
				cacheValue: "value1",
				expiresInSeconds: 10,
				cacheValueResult: null,

				gameRoomId: "zgamez-1",
				rtcLogs: [],
			}
		},

		computed: {
			...mapState(["userinfo", "token", "BUILD_HASH", "BUILD_TIMESTAMP"]),
		},

		methods: {
			...mapActions([
				"getGame",

				"diagnosticsGetServerEnvironmentInfo",
				"diagnosticsGetServerEnvironmentInfo2",
				"diagnosticsSendMail",
				"diagnosticsSendSignalRMessage",
				"diagnosticsSendSignalRMessage2",
				"diagnosticsSetCacheValue",
				"diagnosticsGetCacheValue",
				"diagnosticsGetCacheValue2",
			]),

			async getBuildInfo() {
				const that = this
				DEBUG.log('getServerEnvironmentInfo...')

				that.error = null;
				try {
					that.getServerEnvironmentInfoResult = await that.diagnosticsGetServerEnvironmentInfo();
				} catch (error) {
					that.error = JSON.stringify(error)
					that.notifyError(`an error occurred - ${error.message}`)
				}
			},

			async getServerEnvironmentInfo() {
				const that = this
				DEBUG.log('getServerEnvironmentInfo...')

				that.error = null;
				try {
					that.getServerEnvironmentInfoResult = await that.diagnosticsGetServerEnvironmentInfo();
				} catch (error) {
					that.error = JSON.stringify(error)
					that.notifyError(`an error occurred - ${error.message}`)
				}
			},
			async getServerEnvironmentInfo2() {
				const that = this
				DEBUG.log('getServerEnvironmentInfo2...')

				that.error = null;
				try {
					that.getServerEnvironmentInfo2Result = await that.diagnosticsGetServerEnvironmentInfo2();
				} catch (error) {
					that.error = JSON.stringify(error)
					that.notifyError(`an error occurred - ${error.message}`)
				}
			},

			async sendMail() {
				const that = this
				DEBUG.log('sendMail...')

				that.error = null;
				try {
					const subject = that.subject
					const body = that.body
					const to = that.to

					await that.diagnosticsSendMail({
						subject,
						body,
						to
					})
					that.sendMailResult = 'DONE';
				} catch (error) {
					that.error = JSON.stringify(error)
					that.notifyError(`an error occurred - ${error.message}`)
				}
			},

			async sendSignalRMessage() {
				const that = this
				DEBUG.log('sendSignalRMessage...')

				that.error = null;
				try {
					await that.diagnosticsSendSignalRMessage();
					that.sendSignalRMessageResult = 'SENT';
				} catch (error) {
					that.error = JSON.stringify(error)
					that.notifyError(`an error occurred - ${error.message}`)
				}
			},
			async sendSignalRMessage2() {
				const that = this
				DEBUG.log('sendSignalRMessage2...')

				that.error = null;
				try {
					await that.diagnosticsSendSignalRMessage2();
					that.sendSignalRMessage2Result = 'SENT';
				} catch (error) {
					that.error = JSON.stringify(error)
					that.notifyError(`an error occurred - ${error.message}`)
				}
			},

			async setCacheValue() {
				const that = this
				DEBUG.log('setCacheValue...')

				that.error = null;
				try {
					that.cacheValueResult = await that.diagnosticsSetCacheValue({ key: that.cacheKey, value: that.cacheValue, expiresInSeconds: that.expiresInSeconds });
				} catch (error) {
					that.error = JSON.stringify(error)
					that.notifyError(`an error occurred - ${error.message}`)
				}
			},
			async getCacheValue() {
				const that = this
				DEBUG.log('getCacheValue...')

				that.error = null;
				that.cacheValueResult = null;
				try {
					that.cacheValueResult = await that.diagnosticsGetCacheValue({ key: that.cacheKey });
				} catch (error) {
					that.error = JSON.stringify(error)
					that.notifyError(`an error occurred - ${error.message}`)
				}
			},
			async getCacheValue2() {
				const that = this
				DEBUG.log('getCacheValue2...')

				that.error = null;
				that.cacheValueResult = null;
				try {
					that.cacheValueResult = await that.diagnosticsGetCacheValue2({ key: that.cacheKey });
				} catch (error) {
					that.error = JSON.stringify(error)
					that.notifyError(`an error occurred - ${error.message}`)
				}
			},
		}
	}
</script>
<style lang="scss">
	label {
		font-weight: bold;
	}

	.vjs-tree {
		/*font-size: x-small !important;*/
	}

	.column1 {
		width: 25%;
	}

	.column2 {
		width: 75%;
	}
</style>