<template>
	<span :class="['playingCard-parent']" :style="exstyle"
		@dblclick="handleDblClick">
		<slot name="cardActions"></slot>
		<zcard ref="zcard" :isFaceDown="isFaceDown" 
			:onClickCallback="() => handleClick(this)"  
				:isSelectedCard="isSelectedCard" :rank="rank" :suit="suit" :isMe="isMe" :trayHint="trayHint"/>
	</span>
</template>
<script>
	import DEBUG from '@/common/DEBUG'
	import { Suits } from '@/common/constants'
	import zcard from "@/components/game/zcard";
	
	export default {
		name: "playingcard",
		components: {
			zcard,
		},
		props: {
			toggle:{
				Type:Boolean,
			},
			suit: {
				Type: Number,
				Required: true,
			},
			rank: {
				Type: Number,
				Required: true,
			},
			isFaceDown: {
				Type: Boolean,
				default: true,
			},
			isSelectedCard: {
				Type: Boolean,
			},
			cardCustomClass: {
				Type: String,
				default: ""
			},
			isMe: {
				Type: Boolean,
				default: false,
			},
			src: {
				Type: String,
				Required: true,
			},
			exstyle: {
				Type: String,
				default: "",
				Required: false
			},
			onClickCallback: {
				Type: Function,
				Required: false,
			},
			onDblClickCallback: {
				Type: Function,
				Required: false,
			},
			trayHint: {
				type: String,
			},
		},
		watch: { 
			toggle: function(newVal, oldVal) {
				toggleSelection();
			}
		},
		data() {
			return {
				isSelected: false,
			};
		},

		mounted() {
			//DEBUG.log('CARD - Suit-%o, Rank=%o', this.suit, this.rank);
		},

		methods: {
			handleClick() {
				this.isSelected = this.$refs["zcard"].isSelected;
				if (this.onClickCallback) {
					this.onClickCallback()
				}
			},
			handleDblClick() {
				if (this.onDblClickCallback) {
					this.onDblClickCallback()
				}
			},
			clearSection(){
				console.log("PlayingCard::toggleSelection ", this.isSelectedCard)
				this.isSelectedCard = !this.isSelectedCard;
				this.$refs.zcard.clearSection();
			}
		},
	};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.35s ease;
}

.fade-enter-from,
.fade-leave-active {
  opacity: 0;
}
    
</style>