<template>
    <span :class="isFaceDown ? 'hidden' : 'opened'"
			:style="getStyle" 
			@click="handleClick">
            <img ref="playingCardImg" v-if="cardImagePath" 
				:src="cardImagePath" 
				style="width:100%;height:100%;filter:invert(0%)"/>
    </span>
</template>

<script>
	import { Suits } from '@/common/constants'
	import DEBUG from '@/common/DEBUG';

	export default {
		name: "zcard",
		props: {
			suit: {
				Type: Number,
				Required: true,
			},
			rank: {
				Type: Number,
				Required: true,
			},
			isFaceDown: {
				type: Boolean,
				default: true,
			},
			isMe: {
				type: Boolean,
				default: false,
			},
			isSelectedCard: {
				Type: Boolean,
				default: false,
			},
			src: {
				Type: String,
				Required: true,
			},
			title:{
				Type: String,
				default: "",
			},
			onClickCallback: {
				Type: Function,
				required: false,
			},
			onDblClickCallback: {
				Type: Function,
				required: false,
			},
			trayHint: {
				type: String,
			},
		},
		watch: { 
			isSelectedCard: function(newVal, oldVal) { 
				console.log('ZCard - Prop changed: ', newVal, ' | was: ', oldVal)
				if(newVal) 
					this.$refs["playingCardImg"].style.filter="invert(75%)";
				else 
					this.$refs["playingCardImg"].style.filter="invert(0%)";
			}
		},	
		data() {
			return {
				isSelected: false,
			};
		},

		computed: {
			cardImagePath() {
				const suit = this.suit;
				const rank = this.rank;
				if (rank == 14)
					return `/images/card-dummy.png`;
				return suit == 0 && rank == 0
					? `/images/card-back.png`
					: (suit == 1
						? `/images/cards/m/card_14Joker.svg`
						: `/images/cards/m/card_${rank}${Suits[suit]}.svg`)
			},
			getStyle () {
				if (this.trayHint == "compress")
					return "display:flex; max-width:40px; max-height:50px";
				else if (this.trayHint == "compress2")
					return "display:flex; max-width:56px; max-height:80px";
				else if (this.trayHint == "compressTicTacToe")
					return "display:flex; max-width:56px; max-height:80px";
				// make my hand cards when not shown on table a little bigger
				if(this.isMe)
					return "display:flex; max-width:85px; max-height:120px";
				else 
					return "display:flex; max-width:66px; max-height:95px";
			},
		},

		mounted() {
			//DEBUG.log('CARD - Suit-%o, Rank=%o', this.suit, this.rank);
		},

		methods: {
			handleClick() {
				this.isSelected = !this.isSelected
				
				if (this.onClickCallback) {
					this.onClickCallback()
				}
			},
			clearSelection(){
				this.isSelected = false;
				this.$refs["playingCardImg"].style.filter="invert(0%)";
			},
			handleDblClick() {
				if (this.onDblClickCallback) {
					this.onDblClickCallback()
				}
			},
		},
	};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.35s ease;
}

.fade-enter-from,
.fade-leave-active {
  opacity: 0;
}
</style>